<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="교육 문제풀이 SET 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable&&popupParam.disableEidt"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="question"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable&&popupParam.disableEidt"
                  label="교육 문제풀이 SET명"
                  name="eduQuestionMstName"
                  v-model="question.eduQuestionMstName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  :editable="editable&&popupParam.disableEidt"
                  type="edit"
                  codeGroupCd="EDU_KIND_FST_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="educationKindCdLarge"
                  label="교육종류"
                  v-model="question.educationKindCdLarge"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable&&popupParam.disableEidt"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="question.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="questionGrid"
                  title="문제"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :columns="questionGrid.columns"
                  :isExcelDown="false"
                  :data="question.questions"
                  gridHeight="600px"
                  selection="multiple"
                  rowKey="eduQuestionId"
                  :editable="editable&&popupParam.disableEidt"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="LBLADD" icon="add" @btnClicked="addQuestion" v-if="editable&&popupParam.disableEidt" />
                      <c-btn label="제외" icon="remove" @btnClicked="removeQuestion" v-if="editable && popupParam.disableEidt && question.questions.length > 0" />
                    </q-btn-group>
                  </template>
                  <template v-slot:customArea="{ props }">
                    <template>
                      <q-chip
                        clickable
                        outline square
                        color="blue"
                        text-color="white"
                        style="margin-top:3px !important;"
                        @click="getAnswerList(props.row)">
                        ▶
                      </q-chip>
                    </template>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="answerGrid"
                  title="답변"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :columns="answerGrid.columns"
                  :isExcelDown="false"
                  :data="row.answers"
                  gridHeight="600px"
                  selection="multiple"
                  rowKey="eduAnswerId"
                  :editable="editable&&popupParam.disableEidt"
                >
                  <template slot="table-chip">
                    <q-btn-group outline >
                      <q-chip v-if="selectedQuestionName" outline square>
                          {{ selectedQuestionName }}
                      </q-chip>
                    </q-btn-group>
                  </template>
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="LBLADD" icon="add" @btnClicked="addAnswer" v-if="editable && popupParam.disableEidt && selectedQuestionId" />
                      <c-btn label="제외" icon="remove" @btnClicked="removeAnswer" v-if="editable && popupParam.disableEidt && selectedQuestionId && row.answers.length > 0" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'question-mst-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduQuestionMstId: '',
        disableEidt: true,
      }),
    },
  },
  data() {
    return {
      editable: true,
      isSave: false,
      saveUrl: '',
      mappingType: 'POST',
      question: {
        eduQuestionMstId: '',  // 교육 문제풀이 마스터 일련번호
        eduQuestionMstName: '',  // 교육 문제풀이 SET명
        educationKindCdLarge: null,  // 교육종류(대)
        eduQuestionPassScore: '',  // 물제풀이 통과 기준점수
        useFlag: 'Y',  // 사용여부
        delFlag: 'N',  // 삭제여부
        questions: [],
        delQuestions: [],
      },
      row: {},
      selectedQuestionId: '',
      selectedQuestionName: '',
      questionGrid: {
        columns: [
          {
            required: true,
            name: 'questionSortOrder',
            field: 'questionSortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:70px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'questionContent',
            field: 'questionContent',
            label: '문제 내용',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'questionPoint',
            field: 'questionPoint',
            label: '배점',
            type: 'number',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'custom',
            field: 'custom',
            label: '답변',
            align: 'center',
            style: 'width:60px',
            type: 'custom',
            sortable: false
          },
        ],
      },
      answerGrid: {
        columns: [
          {
            required: true,
            name: 'answerSortOrder',
            field: 'answerSortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:70px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'answerContent',
            field: 'answerContent',
            label: '답변 내용',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'answerFlag',
            field: 'answerFlag',
            label: '정답여부',
            align: 'center',
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.detailUrl = selectConfig.sop.edu.quiz.mst.get.url;
      this.saveUrl = transactionConfig.sop.edu.quiz.mst.update.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.eduQuestionMstId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.eduQuestionMstId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.question, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    addQuestion() {
      if (!this.question.questions) this.question.questions = []; // 초기화
      this.question.questions.splice(0, 0, {
        eduQuestionMstId: this.popupParam.eduQuestionMstId,  // 교육 문제풀이 마스터 일련번호
        eduQuestionId: uid(),  // 교육 문제풀이 문제 일련번호
        questionSortOrder: this.question.questions.length + 1,  // 문제 순번
        questionContent: '',  // 문제내용
        questionPoint: '',  // 문제 배점
        delFlag: 'N',  // 삭제여부
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
        answers: [],
        delAnswers: [],
      })
    },
    removeQuestion() {
      let selectData = this.$refs['questionGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.question.delQuestions) {this.question.delQuestions = [];}
        if (!this.row.delAnswers) {this.row.delAnswers = [];}
        this.$_.forEach(selectData, (item) => { 
          if (this.$_.findIndex(this.question.delQuestions, { eduQuestionId: item.eduQuestionId }) === -1 
            && item.editFlag !== "C") {
            this.question.delQuestions.push(item)
          }
          this.question.questions = this.$_.reject(this.question.questions, item);
        });
        this.$refs['questionGrid'].$refs['compo-table'].clearSelection();

        this.$set(this.$data, 'row', {})
        this.$set(this.$data, 'selectedQuestionId', '')
        this.$set(this.$data, 'selectedQuestionName', '')
      }
    },
    getAnswerList(row) {
      this.row = row
      this.selectedQuestionId = row.eduQuestionId;
      this.selectedQuestionName = row.questionContent;
    },
    addAnswer() {
      this.row.answers.push({
        eduQuestionId: this.selectedQuestionId,  // 교육 문제풀이 문제 일련번호
        eduAnswerId: uid(),  // 교육 문제풀이 답변 일련번호
        answerSortOrder: this.row.answers.length + 1,  // 정답 순번
        answerContent: '',  // 정답내용
        answerFlag: 'N',  // 정답여부
        delFlag: 'N',  // 삭제여부
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId
      })
    },
    removeAnswer() {
      let selectData = this.$refs['answerGrid'].selected;
      
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.row.delAnswers) {this.row.delAnswers = [];}
          if (this.$_.findIndex(this.row.delAnswers, {eduAnswerId: item.eduAnswerId}) === -1 
            && item.editFlag !== "C") {
            this.row.delAnswers.push(item)
          }

          this.row.answers = this.$_.reject(this.row.answers, item);
        });
        this.$refs['answerGrid'].$refs['compo-table'].clearSelection();
      }
    },
    saveData() {
      if (this.popupParam.eduQuestionMstId) {
        this.mappingType = 'PUT';
      } else {
        this.mappingType = 'POST';
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.question.regUserId = this.$store.getters.user.userId;
              this.question.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.eduQuestionMstId = result.data
      this.getDetail();
    },
  }
};
</script>
